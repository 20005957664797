<template>
  <div>
    <v-toolbar flat>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item">
          <b style="color: #1976d2;">Retry Baccarat</b>
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="card-header-form">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="params.search"
            placeholder="Search"
          />
          <div class="input-group-btn">
            <button
              class="btn btn-primary"
              @click="fetchList(params)"
              style="
                border-radius: 0px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
              "
            >
              <i class="fas fa-search" style="border-radius: 0 !important"></i>
            </button>
          </div>
        

          
          <button
            class="ml-2 btn btn-primary"
            @click="createDialog"
            v-if="checkUserPermission('CREATE')"
            style="border-radius: 50px"
          >
            <i class="fas fa-plus-circle" style="border-radius: 0 !important"></i>
          </button>
          <dialog-delete
            :dialog-delete="dialogDelete"
            :darkmode="darkmode"
            v-on:closeDelete="closeDelete"
            v-on:deleteItemConfirm="deleteItemConfirm"
          />
        </div>
      </div>
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import DialogDelete from "../../../components/shared/notify/DialogDelete";

export default {
  components: { DialogDelete },
  props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async deleteItemConfirm() {
      await this.deleteApp({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      await this.fetchList(this.params);
      this.dialogDelete = false;
    },
    async createDialog() {
      this.fetchPopUp(true);
    },
    ...mapActions("$_retryBacc", ["fetchPopUp", "fetchList"]),
  },
};
</script>

<style lang="scss" scoped>
.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  font-size: 14px;
  padding: 5px 15px;
  height: 34px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.input-group-text,
select.form-control:not([size]):not([multiple]),
.form-control:not(.form-control-sm):not(.form-control-lg) {
  height: 36px !important;
}
</style>
